(function() {
    'use strict';

    angular.module('ionicUpload', [
        'angularFileUpload',
        'ionicAlert',
        'ionicData',
        'ionicLogin',
        'ionicRequest',
        'uabDefaultImage',
        'uabDefaultVariable',
        'uabEnvironment'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicUpload').service('UploadService', UploadService);
    
    UploadService.$inject = [
        'DataManagementService',
        'EnvironmentService',
        'FileUploader',
        'LoginService',
        'RequestManagementService'
    ];

    function UploadService(
        DataManagementService,
        EnvironmentService,
        FileUploader,
        LoginService,
        RequestManagementService
    ) {
        var UploadService = this;

        UploadService.getFileTypes = getFileTypes;
        function getFileTypes() {
            return {
                images: [
                    'jpg',
                    'png'
                ],
                videos: [
                    'mp4'
                ]
            };
        }

        UploadService.getUploader = getUploader;
        function getUploader() {
            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'upload');
            options = RequestManagementService.setAction(options, 'upload');

            var url = DataManagementService.buildUrl(options);

            return new FileUploader({
                url: url
            });
        }

        UploadService.upload = upload;
        function upload(formData) {
            var headers = {
                transformRequest: angular.identity,
                headers: {
                    'Content-Type': undefined
                }
            };

            var options = RequestManagementService.getRequest();

            options = RequestManagementService.setModel(options, 'upload');
            options = RequestManagementService.setAction(options, 'upload');

            options = RequestManagementService.setHeader(options, headers);

            options = RequestManagementService.setData(options, formData);

            return DataManagementService.request(options);
        }

        UploadService.reset = reset;
        function reset() {

        }

        UploadService.reset();

        return UploadService;
    }
})();


(function() {
    'use strict';

    angular.module('ionicUpload').controller(
        'UploadController',
        UploadController
    );

    UploadController.$inject = [
        'DefaultImageService',
        'DefaultVariableService',
        'EnvironmentService',
        'LoginService',
        '$scope',
        'UploadService'
    ];

    function UploadController(
        DefaultImageService,
        DefaultVariableService,
        EnvironmentService,
        LoginService,
        $scope,
        UploadService
    ) {
        var UploadController = this;

        $scope.$watch(
            'onUpload',
            function(onUpload) {
                UploadController.onUpload = DefaultVariableService.getPromise(
                    onUpload
                );
            }.bind(UploadController)
        );

        $scope.$watch(
            'target',
            function(target) {
                UploadController.setTarget(target);
            }.bind(UploadController)
        );

        UploadController.clear = clear;
        function clear() {
            UploadService.reset();
            UploadController.reset();
        }

        UploadController.getImagePath = getImagePath;
        function getImagePath() {
            return DefaultImageService.getImage(UploadController.image);
        }

        UploadController.isUploading = isUploading;
        function isUploading() {
            return DefaultVariableService.isDefined(UploadController.uploader) && UploadController.uploader.isUploading;
        }

        UploadController.toggleUpload = toggleUpload;
        function toggleUpload() {
            UploadController.showUpload = !UploadController.showUpload;
        }

        UploadController.onComplete = onComplete;
        function onComplete(fileItem, response) {
            UploadController.image = DefaultVariableService.getObject(
                response.images
            );

            UploadController.target[UploadController.name] = UploadController.image;
            UploadController.target[UploadController.nameId] = UploadController.image.id;

            UploadController.onUpload(UploadController.image);
        }

        UploadController.removeUpload = removeUpload;
        function removeUpload() {
            UploadController.target[UploadController.name] = {};
            UploadController.target[UploadController.nameId] = null;

            UploadController.image = UploadController.target[UploadController.name];
        }

        UploadController.setTarget = setTarget;
        function setTarget(target) {
            UploadController.name = DefaultVariableService.get(
                UploadController.name,
                'image'
            );

            UploadController.nameId = DefaultVariableService.get(
                UploadController.nameId,
                UploadController.name + '_id'
            );

            UploadController.target = DefaultVariableService.getObject(
                target,
                UploadController.target
            );

            if (DefaultVariableService.isDefined(UploadController.target[UploadController.name])) {
                UploadController.image = DefaultVariableService.getObject(
                    target[UploadController.name]
                );
            } else {
                UploadController.image = DefaultVariableService.getObject();
            }
        }

        UploadController.upload = upload;
        function upload() {
            UploadController.uploader.uploadAll();
        }

        UploadController.reset = reset;
        function reset() {
            UploadController.backendUrl = EnvironmentService.get('backendUrl');

            UploadController.files = [];

            UploadController.fileTypes = UploadService.getFileTypes();

            UploadController.showUpload = false;

            UploadController.uploader = UploadService.getUploader();
            UploadController.uploader.onCompleteItem = UploadController.onComplete;
        }

        UploadController.init = init;
        function init() {
            UploadController.reset();
        }

        LoginService.register(UploadController);
    }
})();
(function() {
    'use strict';

    angular.module('ionicUpload').directive('ionicUpload', ionicUpload);

    function ionicUpload() {
        return {
            bindToController: {
                name: '@'
            },
            controller:   'UploadController',
            controllerAs: 'ctrl',
            replace:      true,
            restrict:     'E',
            scope: {
                onUpload: '=',
                target:   '='
            },
            template:'<ion-list><div data-ng-if="!ctrl.image.file_path || ctrl.showUpload"><div data-ng-if="ctrl.uploader.queue.length !== 0" data-ng-repeat="item in ctrl.uploader.queue">{{ ctrl.isUploading() ? \'UPLOADING\' : \'\' }} {{ item.file.name }} ({{ item.file.size / 1024 / 1024 | number:2 }} MB) {{ ctrl.isUploading() ? \'(\' + item.progress + \'%)\' : \'\' }}</div><div data-ng-if="ctrl.uploader.queue.length === 0"><input type="file" nv-file-select uploader="ctrl.uploader"></div><div class="row"><button class="button button-full accept-button" data-ng-class="{ \'col-xl-4\': ctrl.image.file_path, \'col-xl-6\': !ctrl.image.file_path}" data-ng-click="ctrl.upload()" data-ng-disabled="ctrl.uploader.queue.length === 0 || ctrl.isUploading()">{{ ctrl.isUploading() ? \'UPLOADING...\' : \'UPLOAD\' }}</button> <button class="button button-full decline-button" data-ng-class="{ \'col-xl-4\': ctrl.image.file_path, \'col-xl-6\': !ctrl.image.file_path}" data-ng-click="ctrl.uploader.clearQueue()" data-ng-disabled="ctrl.uploader.queue.length === 0 || ctrl.isUploading()">CLEAR</button> <button data-ng-if="ctrl.image.file_path" class="button button-full col-xl-4" data-ng-click="ctrl.toggleUpload()">CANCEL</button></div></div><div data-ng-if="ctrl.image.file_path && !ctrl.showUpload" class="row"><div class="col-3"><img class="ionic-uploaded-image" data-ng-src="{{ ctrl.getImagePath() }}"></div><button class="button button-full accept-button col-5" data-ng-click="ctrl.toggleUpload()">CHANGE IMAGE</button> <button class="button button-full decline-button col-4" data-ng-click="ctrl.removeUpload()">REMOVE IMAGE</button></div></ion-list>'
        };
    }
})();
(function() {
    'use strict';

    angular.module('ionicUpload').controller('UploadCropController', UploadCropController);

    UploadCropController.$inject = [];

    function UploadCropController() {
        var UploadCropController = this;

        UploadCropController.reset = reset;
        function reset() {

        }

        UploadCropController.uploadImage = uploadImage;
        function uploadImage() {
            
        }

        UploadCropController.init = init;
        function init() {
            UploadCropController.reset();
        }

        UploadCropController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicUpload').directive('ionicUploadCrop', ionicUploadCrop);

    function ionicUploadCrop() {
        return {
            bindToController: {
                target: '=',
                key:    '@'
            },
            controller:   'UploadCropController',
            controllerAs: 'ctrl',
            link: function(scope) {
                if (scope.isize) {
                    scope.aratio = scope.isize.w / scope.isize.h;
                }
            },
            restrict: 'E',
            scope: {
                cscale:  '@',
                ainit:   '@',
                isize:   '=',
                pattern: '@'
            },
            template:'<button data-ng-model="ctrl.image" ngf-select accept="image/*">SELECT IMAGE</button><div data-ng-if="ctrl.image" style="padding: 10px 0 10px 0">TITLE: <input type="text" data-ng-model="ctrl.image.name"></div><div class="cropArea" data-ng-model="ctrl.image" ngf-drop ngf-pattern="image/*"><ui-cropper image="ctrl.image" area-type="rectangle" aspect-ratio="aratio" canvas-scale-mode="cscale" area-init-is-relative-to-image="ainit" result-image="ctrl.croppedImage" result-image-format="\'image/jpeg\'" result-image-size="isize"></ui-cropper></div><div data-ng-if="ctrl.image.name"><div class="right"><h4>PREVIEW:</h4><img style="max-width: 175px; max-height: 175px" data-ng-src="{{ ctrl.croppedImage }}"></div><div><button data-ng-click="ctrl.uploadImage()">UPLOAD</button> <button data-ng-click="ctrl.reset()">CLEAR</button></div></div>'
        };
    }
})();